.compose {
  padding: 10px;
  display: flex;
  align-items: center;
  background: white;
  border-top: 1px solid #eeeef1;
  position: sticky;
  bottom: 0;
}

@supports (backdrop-filter: blur(20px)) {
  .compose {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}

.compose-input {
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  line-height: 24px;
  overflow: auto;
  height: auto;
  padding: 2px;
  display: flex;
  width: 100%;
  font-size: 16px;
  color: #252525;
  resize: none;
  font-family: 'Lato', sans-serif;
}

.compose-input::placeholder {
  opacity: 0.3;
}

.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #00bcd4;
}
