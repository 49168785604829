.message {
  display: flex;
  flex-direction: column;
  margin-left: 62px;
}

.message .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.message .bubble-container {
  font-size: 15px;
  display: flex;
}

.message .bubble-text {
  white-space: pre-line;
}

.message .bubble-text img {
  width: 150px;
  height: 110px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 6px;
}

.message .bubble-hours {
  white-space: pre-line;
  color: #a5a5a5;
  display: flex;
  font-size: 11px;
  justify-content: flex-end;
  margin-top: 6px;
}

.message .bubble-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  align-self: flex-end;
  margin-right: 12px;
}

.message.mine {
  margin-right: 62px;
}

.message.mine .bubble-avatar {
  margin-left: 12px;
  margin-right: 0px;
}

.message.end {
  margin-right: 0px;
  margin-left: 0px;
}

.message.start {
  margin-top: 30px;
}

.message.mine .bubble-hours {
  color: white;
}

.message.mine .bubble-container {
  justify-content: flex-end;
}

.message.start .bubble-container .bubble {
  /* margin-top: 10px; */
  border-top-left-radius: 20px;
}

.message.end .bubble-container .bubble {
  border-bottom-left-radius: 20px;
  /* margin-bottom: 10px; */
}

.message .bubble-name {
  color: #bdbdbd;
  font-size: 13px;
  display: flex;
  margin-left: 6px;
  margin-bottom: 10px;
}

.message.mine .bubble-name {
  justify-content: flex-end;
  margin-bottom: 0px;
  margin-right: 4px;
}

.message.mine.start .bubble-container .bubble {
  margin-top: 10px;
  border-top-right-radius: 20px;
}

.message.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
}

.message .bubble-container .bubble {
  margin: 1px 0px;
  background: #f4f4f8;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.message.mine .bubble-container .bubble {
  background: #00bcd4;
  color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
