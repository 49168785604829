.toolbar-button {
  color: #00bcd4;
  font-size: 28px;
  transition: all 0.3s;
}

.toolbar-button:hover {
  cursor: pointer;
  color: #002f3f;
}

.toolbar-button:active {
  color: #00bcd4;
  opacity: 0.25;
}

.toolbar-drash i {
  color: #ff7676;
  transition-duration: 0.3s;
}

.toolbar-drash i:hover {
  color: #ff3e3e;
}
