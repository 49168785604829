.grabber {
}

.grabber > div {
  transform: rotate(5deg);
  cursor: grabbing !important;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.full button {
  cursor: default;
  opacity: 0.3;
}

.draft-finalisation-modal {
  background-color: #FFF;
  padding: 10px 40px;
  overflow: scroll;
}
