.conversation-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  transition-duration: 0.4s;
  position: relative;
}

.conversation-list-item:hover {
  background: #f6f6f9;
  cursor: pointer;
}

.conversation-list-item.bold:hover {
  background: #efefef;
}

.conversation-list-item.active {
  background-color: #f3fdff;
}

.conversation-photo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.conversation-photo.placeholder {
  object-fit: contain;
}

.conversation-title {
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  margin: 0;
  color: #607d8b;
}

.conversation-from {
  font-size: 13px;
  font-weight: 100;
  margin: 0;
  margin-bottom: 6px;
  color: #607d8b;
}

.conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 0;
}

.conversation-list-item .my-classified {
  color: #00bcd4;
  position: absolute;
  top: 5px;
  right: 5px;
}

.bold,
.bold div,
.bold span,
.bold h1 {
  font-weight: bold !important;
}

.bold {
  background-color: #efefef;
}

.conversation-fa-active {
  right: 3px;
  position: absolute;
  display: none;
}

.conversation-list-item.active .conversation-fa-active {
  display: block;
}
