.message-list-container {
  padding: 10px;
  padding-bottom: 20px;
  min-height: calc(100vh - 190px);
}
.message-list {
  position: relative;
  height: 100%;
}

@media (max-width: 573px) {
  .message-list-container {
    padding-bottom: 65px;
    padding-top: 60px;
  }
  .message-list .compose {
    position: fixed;
    width: 100%;
  }
  .message-list .toolbar {
    position: fixed;
    top: 55px;
    width: 100%;
  }
}
