.messenger {
  display: flex;
  width: 100%;
  height: calc(100vh - 77px);
  background: #eeeef1;
  grid-template-columns: 350px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.sidebar {
  background: white;
  width: 33.33%;
  min-width: 300px;
  transition-duration: 0.3s;
}

@media (max-width: 573px) {
  .sidebar {
    min-width: 100%;
    max-width: 100%;
  }
}

.messenger.expanded .sidebar {
  min-width: 0px;
  width: 0%;
}

.content {
  background: white;
  width: 100%;
}

.content .ic-back {
  opacity: 0;
  transition-duration: 0.3s;
}

.messenger.expanded .content .ic-back {
  opacity: 1;
}

.footer {
  grid-column-start: 2;
  background: white;
}

footer.messagerie {
  display: none !important;
}
